<template>
  <div>
    <a-spin tip="Loading...">
      <div class="spin-content">
        OAuth登录中...
      </div>
    </a-spin>
  </div>
</template>

<script>

export default ({
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
    this.$axios.get('/api/platform/user/userInfo')
      .then(res => {
        if (res.code === 0) {
          this.$store.commit('setUserInfo', res.data)
          this.$router.push('/')
        } else {
          this.$store.commit('clear')
        }
      })
  },
})

</script>

<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
</style>